<!-- 学员授权 -->
<template>
    <div class="studentEmpower">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="学员编号">
                <el-input v-model="form.number" placeholder="请输入学员编号"></el-input>
            </el-form-item>
            <el-form-item label="学员姓名">
                <el-input v-model="form.name" placeholder="请输入学员姓名"></el-input>
            </el-form-item>
            <el-form-item label="选择学校">
                <!-- <el-input v-model="form.school" placeholder="请输入"></el-input> -->
                <el-select v-model="form.school" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.schoolChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" :clearable="true">
                    <el-option v-for="item in form.typeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="" style="margin-left:22px">
            <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                查询
            </el-button>
            <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="close">
                重置
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:98%;flex: 1;" height="650" id="out-table">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="number" label="编号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="类型" align="center" width="80">
            </el-table-column>
            <el-table-column prop="username" label="学员姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="学号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="mcode" label="助记码" align="center" width="120">
            </el-table-column>
            <el-table-column prop="start_school" label="入学年份" align="center" width="200">
            </el-table-column>
            <el-table-column prop="grade" label="现年级" align="center" width="100">
            </el-table-column>
            <el-table-column prop="school_name" label="就读学校" align="center" width="150">
            </el-table-column>
            <el-table-column prop="parent_name" label="家长姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_aid" label="录入人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_time" label="录入时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="save_aid" label="修改人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="save_time" label="最近调整时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-view" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                        @click="details(scope.$index, scope.row)">
                        详情
                    </el-button>
                    <el-button icon="el-icon-setting" size="mini" style="background-color:#E7B43C;color:#FFFFFF ;"
                        @click="empower(scope.$index, scope.row)">
                        授权
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--授权弹框 -->
        <el-dialog title="学员授权" :visible.sync="dialogFormVisible">
            <el-divider></el-divider>
            <el-form :model="ruleForm" ref="ruleForm" :rules="rules"  class="form-1" label-width="100px">
                <el-form-item label="学员姓名" prop="studentName">
                    <el-input v-model="ruleForm.studentName" placeholder="请输入学员姓名"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="studentNum">
                    <el-input v-model="ruleForm.studentNum" placeholder="请输入登录账号"></el-input>
                </el-form-item>
                <el-form-item label="学生密码" prop="studentPwd">
                    <el-input v-model="ruleForm.studentPwd" placeholder="请输入学生密码"></el-input>
                </el-form-item>
                <el-form-item label="账号有效期">
                    <el-date-picker v-model="ruleForm.effective" type="date" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span class="span-txt">[注：若登录账号校验有重复，账号修正为身份证后12位]</span>
            <span class="span-txt">[注2：若登录账号校验有重复，账号请人工修正为手机号]</span>
            <el-divider></el-divider>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" style="background-color: #1884FF;color: #fff;" @click="check">校验</el-button>
                <el-button size="small" style="background-color: #18BC9C;color: #fff;"
                @click="submitForm('ruleForm')">保存</el-button>
                <el-button size="small" class="btn" @click="resetForm('ruleForm')">取消</el-button>
            </div>
        </el-dialog>


        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import moment from "moment"
    export default {
        data() {
            return {
                form: {
                    number: "", //学员编号
                    name: "", //学员姓名
                    school: "", //学校
                    schoolChoose: [
                        {
                                label: "入学",
                                value: "1"
                            },
                            {
                                label: "结业",
                                value: "2"
                            },
                            {
                                label: "未入学",
                                value: "3"
                            }
                    ], //学校选择
                    type: "", //类型
                    typeChoose: [
                        {
                                label: "授权",
                                value: "1"
                            },
                            {
                                label: "未授权",
                                value: "2"
                            },
                    ], //类型选择
                },
                tableData: [],
                dialogFormVisible: false,
                ruleForm: {
                    studentName: "", //学员姓名
                    studentNum: "", //登录账号
                    studentPwd: "", //学生密码
                    effective: "" ,//有效日期
                    id:""//id
                },
                rules:{
                    studentName:[{
                            required: true,
                            message: '学员姓名不能为空',
                            trigger: 'blur'
                        }],
                   studentNum:[{
                           required: true,
                           message: '登录账号不能为空',
                           trigger: 'blur'
                       }],
                    studentPwd:[{
                           required: true,
                           message: '学生密码不能为空',
                           trigger: 'blur'
                       }], 
                },
                currentPage: 1,
                currentLimit: 10,
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods: {
            getList() { //获取数据
                this.$request({
                    url: '/api/student/list',
                    method: 'POST',
                    data: {
                        number: this.form.number,
                        username: this.form.name,
                        school_name: this.form.school,
                        school_type: this.form.type,
                        page: this.currentPage,
                        limit: this.currentLimit
                    }
                }).then(res => {
                    console.log(res)
                    this.tableData = res.data.list
                    this.total = res.data.count
                })
            },
            handleSizeChange(val) {
                this.currentLimit = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getList()
            },
            details(index, row) { //详情
                this.$router.push({
                    path: "detailsStudentEmpower",
                    query: {
                        id:row.id
                    }
                })
            },
            // 查询
            query() {
            	this.getList()
            },
            // 重置
            close() { 
            		this.form.number= ""//学员编号
            		this.form.name= "" //学员姓名
            		this.form.school="" //学校
                    this.form.type=''
            		// this.form.belonging= ""//所属机构 
            },
            empower(index, row) {//授权
                this.dialogFormVisible = true
                console.log(row)
                this.ruleForm.studentName=row.username
                this.ruleForm.id=row.id
            },
            check(){
                this.$request({
                    url: '/api/student/authorize_jy',
                    method: 'POST',
                    data: {
                       student_user:this.ruleForm.studentName
                    }
                }).then(res => {
                    console.log(res)
                    if(res.code == 1){
                        this.$message({
                            message: '该账号可使用',
                            type: 'success'
                        })
                    }
                })
            },
            submitForm(ruleForm) {//修改提交保存
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        this.ruleForm.effective=moment(this.ruleForm.effective).format("YYYY-MM-DD")
                        this.$request({
                            url: '/api/student/authorize',
                            method: 'POST',
                            data: {
                                id:this.ruleForm.id,	
                                username:this.ruleForm.studentName,
                                student_user:this.ruleForm.studentNum,
                                effective_time:this.ruleForm.effective,
                                student_pwd:this.ruleForm.studentPwd
                            }
                        }).then(res => {
                            console.log(res)
                            if (res.code == 1) {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.dialogFormVisible=false
                                }, 1500)
                                this.refresh()
                                this.ruleForm.id=''
                                this.ruleForm.studentName=''
                                this.ruleForm.studentNum=''
                                this.ruleForm.effective=''
                                this.ruleForm.studentPwd=''
                            }
                            if (res.code == 0) {
                                this.$message({
                                    message: '账号已存在',
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.dialogFormVisible=false
                                }, 1500)
                                this.refresh()
                                 this.ruleForm.id=''
                                 this.ruleForm.studentName=''
                                 this.ruleForm.studentNum=''
                                 this.ruleForm.effective=''
                                 this.ruleForm.studentPwd=''
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        this.dialogFormVisible=false
                        this.$refs[ruleForm].resetFields();
                        return false;
                    }
                });
            },
            resetForm(ruleForm) { //取消重置验证
                this.dialogFormVisible = false
                this.$refs[ruleForm].resetFields();
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
        }
    }
</script>

<style scoped="scoped">
    .studentEmpower {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .form {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }

    /* 弹出框 */
    .dialog-footer {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        /* padding-bottom: 20px; */
    }

    ::v-deep .el-dialog {
        width: 667px !important;
        /* height: 485px !important; */
    }

    ::v-deep.el-dialog__header {
        border: #000000 1px solid;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }

    .el-divider--horizontal {
        margin: 0px;
    }

    .form-1 {
        width: 400px;
        margin: 0px auto;
        margin-top: 10px;
    }

    .form-1::v-deep .el-input__inner {
        width: 300px;
        height: 37px;
    }

    .form-1::v-deep .el-form-item__label {
        color: #000000;
    }

    .span-txt {
        padding-left: 20px;
        text-align: center;
        margin: 5px auto;
        display: block;
        width: 400px;
        font-size: 14px;
        color: red;
    }
</style>
